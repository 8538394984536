import React, { Fragment } from 'react';

import Hero from '../components/private/hero';
import Properties from '../components/private/properties';
import Reviews from '../components/private/reviews';
import Contact from '../components/contact';

export default ()=> {

  return(
    <Fragment>
      <Hero />
      <Properties />
      <Reviews />
      </Fragment>
  )
}